"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isNotEmptyString = isNotEmptyString;
exports.isEmptyString = isEmptyString;
exports.equalsIgnoreCase = equalsIgnoreCase;
function isNotEmptyString(str) {
  if (typeof str === 'string') {
    return str.trim().length > 0;
  }
  return false;
}
function isEmptyString(str) {
  if (typeof str === 'string') {
    return str.trim().length < 1;
  }
  return true;
}
function equalsIgnoreCase(str1, str2) {
  if (str1 === str2) {
    return true;
  }
  return typeof str1 === 'string' && typeof str2 === 'string' && str1.length === str2.length && str1.toLowerCase() === str2.toLowerCase();
}